@import url('https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&family=Roboto+Condensed:wght@300;400;700&display=swap');
body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mio-container {
  display: flex;
  flex-wrap: wrap;
  .header {
    width: 100%;
  }
  .direct {
    color: red;
  }
  .no-inventory {
    display: none;
  }
  .for-sale {
    color: red;
  }
  .tshirt {
    display: flex;
    flex-wrap: wrap;
    width: calc(40% - 40px);
    padding: 20px;
    .form {
      width: 100%;
    }
    .image {
      width: 100%;
    }
  }
  .conversion {
    width: calc(60% - 40px);
    padding: 20px;
    a {
      display: block;
      text-align: center;
      margin: 40px auto;
      padding: 30px 20px;
      color: #fff;
      background: #333;
      width: 320px;
      max-width: 80%;
      font-size: 2rem;
      text-decoration: none;
      &:hover {
        background: #666;
      }
      &:after {
        content: " >";
      }
      &.back {
        color: #666;
        background: #fff;
        border: 2px solid #ccc;
        &:hover {
          background: #ccc;
        }
        &:before {
          content: "< ";
        }
        &:after {
          content: "";
        }
      }
    }
    .note {
      color: gray;
    }
  }
  .samples {
    width: 100%;
    margin-bottom: 50px;
    .slick-slider {
      &.slider_pc {
        display: block;
      }
      &.slider_sp {
        display: none;
      }
      .slick-slide {
        img {
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mio-container {
    .header {
      width: 100%;
    }
    .tshirt {
      width: calc(100%);
      padding: 0px;
    }
    .conversion {
      width: calc(100%);
      padding: 0px;
      a {
        margin-top: 0px;
      }
    }
    .samples {
      width: 100%;
      .slick-slider {
        &.slider_pc {
          display: none;
        }
        &.slider_sp {
          display: block;
        }
      }
    }
  }
}
