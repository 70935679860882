.gnav {
  height: 72px;
  display: flex;
  .logo {
    padding: 6px 0;
    img {
      height: 60px;
    }
  }
  ul {
    display: flex;
    list-style: none;
    padding-left: 20px;
    li {
      line-height: 40px;
      margin: 0 10px;
      a {
        text-decoration: none;
        color: #333;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
