.gfooter {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
  a {
    color: #333;
    display: inline-block;
    padding: 0 5px;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }
}
